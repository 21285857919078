import * as GameFeature from './game';
import {GameSlice, GameSaga, GameCalls} from './game';
import {combineReducers} from '@reduxjs/toolkit';
import {all} from 'redux-saga/effects';

export const rootFeature = {
	GameFeature,
};

export const rootReducer = combineReducers({
	[GameSlice.GAME]: GameSlice.gameReducer,
});

export const rootSaga = function* root() {
	yield all([
		GameSaga.watchGetPlayer(),
		GameSaga.watchGetPlayerSecrets(),
		GameSaga.watchAddPlayer(),
		GameSaga.watchUpdatePlayer(),
		GameSaga.watchGetStats(),
		GameSaga.watchGetBasicSecrets(),
		GameSaga.watchGetSecret(),
		GameSaga.watchAddAction(),
	]);
};

export const rootCall = {
	GameCalls,
};
