import {createSelector, createSlice} from '@reduxjs/toolkit';
import type {RootState} from 'redux/store';
import {BasicSecret, Player, PlayerSecret, Secret, Stats} from './entities';
import {log} from 'helpers';

export interface GameState {
	player: Player;
	playerSecrets: PlayerSecret[];
	secret: Secret;
	stats: Stats;
	basicSecrets: BasicSecret[];
	isLoading: boolean;
	dummy: any;
}

const initialState: GameState = {
	player: {
		token: '',
		first_name: '',
		last_name: '',
		total_balance: 0,
		current_balance: 0,
		device_type: '',
		mobile_vendor: '',
		mobile_model: '',
		os_name: '',
		os_version: '',
		browser_name: '',
		browser_version: '',
		engine_name: '',
		engine_version: '',
		user_agent: '',
	},
	playerSecrets: [],
	secret: {
		code: '',
		key: '',
		value: '',
		type: 0,
		is_active: false,
	},
	stats: {
		active: 0,
		inactive: 0,
	},
	basicSecrets: [],
	isLoading: false,
	dummy: null,
};

const resetState = (state: GameState) => {
	state.player = initialState.player;
	state.playerSecrets = initialState.playerSecrets;
	state.secret = initialState.secret;
	state.stats = initialState.stats;
	state.basicSecrets = initialState.basicSecrets;
	state.isLoading = initialState.isLoading;
};

const slice = createSlice({
	name: 'GAME',
	initialState,
	reducers: {
		stateUpdater: (state: any, {payload: {key, value}}: {payload: {key: keyof GameState; value: any}}) => {
			log.console.info('reducer : stateUpdater');
			state[key] = value;
		},
		resetState: (state) => {
			log.console.info('reducer : resetState');
			resetState(state);
		},

		/**
		 *********************************
		 ********** GET PLAYER ***********
		 *********************************
		 **/
		getPlayer: (state, {payload: {token}}) => {
			log.console.info('reducer : getPlayer');
			state.player.token = token;
			state.isLoading = true;
		},
		getPlayerSuccess: (state, {payload}: {payload: Player}) => {
			log.console.success('reducer : getPlayer success');
			state.player = payload;
			state.isLoading = false;
		},
		getPlayerFail: (state) => {
			log.console.error('reducer : getPlayer fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 ***** GET PLAYER SECRETS ********
		 *********************************
		 **/
		getPlayerSecrets: (state, {payload: {token}}) => {
			log.console.info('reducer : getPlayerSecrets');
			console.log(token);
			state.player.token = token;
			state.isLoading = true;
		},
		getPlayerSecretsSuccess: (state, {payload}: {payload: PlayerSecret[]}) => {
			log.console.success('reducer : getPlayerSecrets success');
			state.playerSecrets = payload;
			state.isLoading = false;
		},
		getPlayerSecretsFail: (state) => {
			log.console.error('reducer : getPlayerSecrets fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 ********** ADD PLAYER ***********
		 *********************************
		 **/
		addPlayer: (state, {payload}: {payload: {player: Player}}) => {
			log.console.info('reducer : addPlayer');
			state.player = payload.player;
			state.isLoading = true;
		},
		addPlayerSuccess: (state) => {
			log.console.success('reducer : addPlayer success');
			state.isLoading = false;
		},
		addPlayerFail: (state) => {
			log.console.error('reducer : addPlayer fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 ******** UPDATE PLAYER **********
		 *********************************
		 **/
		updatePlayer: (state, {payload: {firstName, lastName}}) => {
			log.console.info('reducer : updatePlayer');
			state.player.first_name = firstName;
			state.player.last_name = lastName;
			state.isLoading = true;
		},
		updatePlayerSuccess: (state) => {
			log.console.success('reducer : updatePlayer success');
			state.isLoading = false;
		},
		updatePlayerFail: (state) => {
			log.console.error('reducer : updatePlayer fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 *********** GET STATS ***********
		 *********************************
		 **/
		getStats: (state) => {
			log.console.info('reducer : getStats');
			state.isLoading = true;
		},
		getStatsSuccess: (state, {payload}: {payload: Stats}) => {
			log.console.success('reducer : getStats success');
			state.stats = payload;
			state.isLoading = false;
		},
		getStatsFail: (state) => {
			log.console.error('reducer : getStats fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 ******* GET BASIC SECRETS *******
		 *********************************
		 **/
		getBasicSecrets: (state) => {
			log.console.info('reducer : getBasicSecrets');
			state.isLoading = true;
		},
		getBasicSecretsSuccess: (state, {payload}: {payload: BasicSecret[]}) => {
			log.console.success('reducer : getBasicSecrets success');
			state.basicSecrets = payload;
			state.isLoading = false;
		},
		getBasicSecretsFail: (state) => {
			log.console.error('reducer : getBasicSecrets fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 ********** GET SECRET ***********
		 *********************************
		 **/
		getSecret: (state, {payload: {code}}) => {
			log.console.info('reducer : getSecret');
			state.secret.code = code;
			state.isLoading = true;
		},
		getSecretSuccess: (state, {payload}: {payload: Secret}) => {
			log.console.success('reducer : getSecret success');
			state.secret = payload;
			state.isLoading = false;
		},
		getSecretFail: (state) => {
			log.console.error('reducer : getSecret fail');
			state.isLoading = false;
		},

		/**
		 *********************************
		 ********* ADD ACTION ************
		 *********************************
		 **/
		addAction: (state) => {
			log.console.info('reducer : addAction');
			state.isLoading = true;
		},
		addActionSuccess: (state) => {
			log.console.success('reducer : addAction success');
			state.isLoading = false;
		},
		addActionFail: (state) => {
			log.console.error('reducer : addAction fail');
			state.isLoading = false;
		},
	},
});

const selectPlayer = createSelector(
	(state: GameState) => state.player,
	(player: Player) => player,
);
const selectPlayerSecrets = createSelector(
	(state: GameState) => state.playerSecrets,
	(playerSecrets: PlayerSecret[]) => playerSecrets,
);
const selectSecret = createSelector(
	(state: GameState) => state.secret,
	(secret: Secret) => secret,
);
const selectStats = createSelector(
	(state: GameState) => state.stats,
	(stats: Stats) => stats,
);
const selectBasicSecrets = createSelector(
	(state: GameState) => state.basicSecrets,
	(basicSecrets: BasicSecret[]) => basicSecrets,
);
const selectIsLoading = createSelector(
	(state: GameState) => state.isLoading,
	(isLoading: boolean) => isLoading,
);
const selectAllState = createSelector(
	selectPlayer,
	selectPlayerSecrets,
	selectSecret,
	selectStats,
	selectBasicSecrets,
	selectIsLoading,
	(player, playerSecrets, secret, stats, basicSecrets, isLoading) => {
		return {
			player,
			playerSecrets,
			secret,
			stats,
			basicSecrets,
			isLoading,
		};
	},
);

export const gameSelector = {
	all: (state: RootState) => selectAllState(state[slice.name]),
};
export const GAME = slice.name;
export const gameReducer = slice.reducer;
export const gameAction = slice.actions;
