import {AxiosResponse} from 'axios';
import {APIResponse} from 'helpers/server/helper';
import {BasicSecret, Player, PlayerSecret, Secret, Stats} from './entities';
import {log, server} from 'helpers';

/**
 ***********************************************************
 ************************ Get player ***********************
 ***********************************************************
 **/
export interface GetPlayerRequest {
	token: string;
}

export interface GetPlayerResponse {
	status: boolean;
	player?: Player;
}

export interface GetPlayerApiResponse {
	player: Player;
}

export const getPlayer = async (request: GetPlayerRequest): Promise<GetPlayerResponse> => {
	const payload = {
		token: request.token,
	};
	return server.request
		.get<GetPlayerApiResponse>({
			path: `/player/${payload.token}`,
		})
		.then((res: AxiosResponse<APIResponse<GetPlayerApiResponse>, any>): GetPlayerResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Player has been successfully gotten.');
				return {
					status: true,
					player: res.data.data?.player,
				};
			} else {
				log.console.error('Player has not been gotten.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 ******************** Get player secrets *******************
 ***********************************************************
 **/
export interface GetPlayerSecretsRequest {
	token: string;
}

export interface GetPlayerSecretsResponse {
	status: boolean;
	secrets?: PlayerSecret[];
}

export interface GetPlayerSecretsApiResponse {
	results: PlayerSecret[];
}

export const getPlayerSecrets = async (request: GetPlayerSecretsRequest): Promise<GetPlayerSecretsResponse> => {
	const payload = {
		token: request.token,
	};
	return server.request
		.get<GetPlayerSecretsApiResponse>({
			path: `/player/secrets/${payload.token}`,
		})
		.then((res: AxiosResponse<APIResponse<GetPlayerSecretsApiResponse>, any>): GetPlayerSecretsResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Player secrets has been successfully geted.');
				return {
					status: true,
					secrets: res.data.data?.results,
				};
			} else {
				log.console.error('Player secrets has not been gotten.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 ********************** Add player *************************
 ***********************************************************
 **/
export interface AddPlayerRequest {
	player: Player;
}

export interface AddPlayerResponse {
	status: boolean;
	playerId?: number;
}

export interface AddPlayerApiResponse {
	result: number;
}

export const addPlayer = async (request: AddPlayerRequest): Promise<AddPlayerResponse> => {
	const payload = {
		token: request.player.token,
		device_type: request.player.device_type,
		mobile_vendor: request.player.mobile_vendor,
		mobile_model: request.player.mobile_model,
		os_name: request.player.os_name,
		os_version: request.player.os_version,
		browser_name: request.player.browser_name,
		browser_version: request.player.browser_version,
		engine_name: request.player.engine_name,
		engine_version: request.player.engine_version,
		user_agent: request.player.user_agent,
	};
	return server.request
		.post<AddPlayerApiResponse>({
			path: `/player/add`,
			data: JSON.stringify(payload),
		})
		.then((res: AxiosResponse<APIResponse<AddPlayerApiResponse>, any>): AddPlayerResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Player has been successfully added.');
				return {
					status: true,
					playerId: res.data.data?.result,
				};
			} else {
				log.console.error('Player has not been added.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 ******************** Update player ************************
 ***********************************************************
 **/
export interface UpdatePlayerRequest {
	token: string;
	first_name: string;
	last_name: string;
}

export interface UpdatePlayerResponse {
	status: boolean;
	result?: number;
}

export interface UpdatePlayerApiResponse {
	result: number;
}

export const updatePlayer = async (request: UpdatePlayerRequest): Promise<UpdatePlayerResponse> => {
	const payload = {
		token: request.token,
		first_name: request.first_name,
		last_name: request.last_name,
	};
	return server.request
		.post<UpdatePlayerApiResponse>({
			path: `/player/update`,
			data: JSON.stringify(payload),
		})
		.then((res: AxiosResponse<APIResponse<UpdatePlayerApiResponse>, any>): UpdatePlayerResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Player has been successfully updated.');
				return {
					status: true,
					result: res.data.data?.result,
				};
			} else {
				log.console.error('Player has not been updated.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 *********************** Get stats *************************
 ***********************************************************
 **/
export interface GetStatsRequest {
	gameId: number;
}

export interface GetStatsResponse {
	status: boolean;
	stats?: Stats;
}

export interface GetStatsApiResponse {
	result: Stats;
}

export const getStats = async (request: GetStatsRequest): Promise<GetStatsResponse> => {
	const payload = {
		gameId: request.gameId,
	};
	return server.request
		.get<GetStatsApiResponse>({
			path: `/game/${payload.gameId}/stats`,
		})
		.then((res: AxiosResponse<APIResponse<GetStatsApiResponse>, any>): GetStatsResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Stats has been successfully fetched.');
				return {
					status: true,
					stats: res.data.data?.result,
				};
			} else {
				log.console.error('Stats has not been fetched.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 ******************* Get basic secrets *********************
 ***********************************************************
 **/
export interface GetBasicSecretsRequest {}

export interface GetBasicSecretsResponse {
	status: boolean;
	basicSecrets?: BasicSecret[];
}

export interface GetBasicSecretsApiResponse {
	results: BasicSecret[];
}

export const getBasicSecrets = async (request: GetBasicSecretsRequest): Promise<GetBasicSecretsResponse> => {
	return server.request
		.get<GetBasicSecretsApiResponse>({
			path: `/secrets/basic`,
		})
		.then((res: AxiosResponse<APIResponse<GetBasicSecretsApiResponse>, any>): GetBasicSecretsResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Basic secrets has been successfully fetched.');
				return {
					status: true,
					basicSecrets: res.data.data?.results,
				};
			} else {
				log.console.error('Basic secrets has not been fetched.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 ********************** Get secret *************************
 ***********************************************************
 **/
export interface GetSecretRequest {
	code: string;
}

export interface GetSecretResponse {
	status: boolean;
	secret?: Secret;
}

export interface GetSecretApiResponse {
	result: Secret;
}

export const getSecret = async (request: GetSecretRequest): Promise<GetSecretResponse> => {
	const payload = {
		code: request.code,
	};
	return server.request
		.get<GetSecretApiResponse>({
			path: `/secret/${payload.code}`,
		})
		.then((res: AxiosResponse<APIResponse<GetSecretApiResponse>, any>): GetSecretResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Secret has been successfully fetched.');
				return {
					status: true,
					secret: res.data.data?.result,
				};
			} else {
				log.console.error('Secret has not been fetched.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};

/**
 ***********************************************************
 ********************* Add action **************************
 ***********************************************************
 **/
export interface AddActionRequest {
	playerToken: string;
	secretCode: string;
}

export interface AddActionResponse {
	status: boolean;
	counter?: number;
}

export interface AddActionApiResponse {
	result: number;
}

export const addAction = async (request: AddActionRequest): Promise<AddActionResponse> => {
	const payload = {
		playerToken: request.playerToken,
		secretCode: request.secretCode,
	};
	return server.request
		.post<AddActionApiResponse>({
			path: `/action/add`,
			data: JSON.stringify(payload),
		})
		.then((res: AxiosResponse<APIResponse<AddActionApiResponse>, any>): AddActionResponse => {
			if (res.status === 200 && res.data.status === 'success') {
				log.console.success('Action has been successfully added.');
				return {
					status: true,
					counter: res.data.data?.result,
				};
			} else {
				log.console.error('Action has not been added.', res);
				return {
					status: false,
				};
			}
		})
		.catch((error: any) => {
			const message = error?.response?.data?.message ?? 'Server is unreachable. Please, try again later.';
			log.console.error(message, error?.response);
			return {
				status: false,
				errorHandler: {
					redirectTo500: true,
				},
			};
		});
};
