import React from 'react';
import {useNavigate} from 'react-router-dom';

function Error() {
	const navigate = useNavigate();

	return (
		<main>
			<div className="flex flex-col h-screen">
				<div className="h-[25%] sm:h-[35%] w-full">
					<img className="h-full w-full object-cover" src={require('assets/qrworld.jfif')} />
				</div>
				<div className="ml-8 xs:ml-12 sm:ml-16 lg:ml-28">
					<div
						onClick={() => navigate('/')}
						className="
						h-14 w-14 xs:h-20 xs:w-20 sm:h-24 sm:w-24 lg:h-32 lg:w-32
						-translate-y-6 xs:-translate-y-8 sm:-translate-y-12 lg:-translate-y-16">
						<img className="h-full w-full object-cover" src={require('assets/logo.jpg')} />
					</div>
					<div className="flex flex-col">
						<span className="font-bold text-2xl xs:text-3xl sm:text-4xl">Zein Games</span>
						<span className="mt-1 xs:mt-3 sm:mt-6 pl-2 sm:pl-4 text-xs xs:text-base sm:text-lg border-l-2 xs:border-l-4 border-l-black">
							Şəhərinizi kəşf edin və qazanın! 🔎 💰
						</span>
					</div>
				</div>
				<div className="mt-8 ml-8 xs:ml-0 xs:self-center xs:items-center flex flex-col">
					<span className="font-bold text-lg xs:text-xl sm:text-2xl">Bu səhifə mövcud deyil.</span>
				</div>
				<div className="mt-12 flex self-center items-center">
					<span onClick={() => navigate('/')} className="text-[#1036A2] cursor-pointer font-bold text-lg xs:text-xl sm:text-2xl">
						Ana səhifəyə qayıt ⬅
					</span>
				</div>
			</div>
		</main>
	);
}

export default Error;
