import {call, put, select, takeLeading} from 'redux-saga/effects';
import * as GameCalls from './calls';
import * as GameSlice from './slice';
import {Player} from './entities';
import {history} from 'helpers/routing';
import {log, device, storage} from 'helpers';

/**
 ***********************************************************
 ************************ Get player ***********************
 ***********************************************************
 **/
function* handleGetPlayer() {
	const {addPlayer, getPlayerSuccess, getPlayerFail} = GameSlice.gameAction;

	try {
		const getPlayerState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const getPlayerRequest: GameCalls.GetPlayerRequest = {
			token: getPlayerState.player.token,
		};
		const getPlayerResponse: GameCalls.GetPlayerResponse = yield call(GameCalls.getPlayer, getPlayerRequest);
		if (getPlayerResponse.status && getPlayerResponse.player) {
			yield put(getPlayerSuccess(getPlayerResponse.player));
		} else {
			const deviceInfo = device.getDeviceInfo();
			const player: Player = {
				token: getPlayerState.player.token,
				device_type: deviceInfo.device_type,
				mobile_vendor: deviceInfo.mobile_vendor,
				mobile_model: deviceInfo.mobile_model,
				os_name: deviceInfo.os_name,
				os_version: deviceInfo.os_version,
				browser_name: deviceInfo.browser_name,
				browser_version: deviceInfo.browser_version,
				engine_name: deviceInfo.engine_name,
				engine_version: deviceInfo.engine_version,
				user_agent: deviceInfo.user_agent,
			};
			yield put(addPlayer({player}));
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(getPlayerFail());
	}
}

function* watchGetPlayer() {
	const {getPlayer} = GameSlice.gameAction;
	yield takeLeading(getPlayer, handleGetPlayer);
}

/**
 ***********************************************************
 ******************** Get player secrets *******************
 ***********************************************************
 **/
function* handleGetPlayerSecrets() {
	const {getPlayerSecretsSuccess, getPlayerSecretsFail} = GameSlice.gameAction;

	try {
		const getPlayerSecretsState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const getPlayerSecretsRequest: GameCalls.GetPlayerSecretsRequest = {
			token: getPlayerSecretsState.player.token,
		};
		const getPlayerSecretsResponse: GameCalls.GetPlayerSecretsResponse = yield call(GameCalls.getPlayerSecrets, getPlayerSecretsRequest);
		if (getPlayerSecretsResponse.status && getPlayerSecretsResponse.secrets) {
			yield put(getPlayerSecretsSuccess(getPlayerSecretsResponse.secrets));
		} else {
			yield put(getPlayerSecretsFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(getPlayerSecretsFail());
	}
}

function* watchGetPlayerSecrets() {
	const {getPlayerSecrets} = GameSlice.gameAction;
	yield takeLeading(getPlayerSecrets, handleGetPlayerSecrets);
}

/**
 ***********************************************************
 ********************** Add player *************************
 ***********************************************************
 **/
function* handleAddPlayer() {
	const {getPlayer, addPlayerSuccess, addPlayerFail} = GameSlice.gameAction;
	try {
		const addPlayerState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const addPlayerRequest: GameCalls.AddPlayerRequest = {
			player: addPlayerState.player,
		};
		const addPlayerResponse: GameCalls.AddPlayerResponse = yield call(GameCalls.addPlayer, addPlayerRequest);
		if (addPlayerResponse.status && addPlayerResponse.playerId && addPlayerResponse.playerId > 0) {
			yield put(addPlayerSuccess());
			yield put(getPlayer({token: addPlayerState.player.token}));
		} else {
			yield put(addPlayerFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(addPlayerFail());
	}
}

function* watchAddPlayer() {
	const {addPlayer} = GameSlice.gameAction;
	yield takeLeading(addPlayer, handleAddPlayer);
}

/**
 ***********************************************************
 ******************** Update player ************************
 ***********************************************************
 **/
function* handleUpdatePlayer() {
	const {updatePlayerSuccess, updatePlayerFail} = GameSlice.gameAction;
	try {
		const updatePlayerState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const updatePlayerRequest: GameCalls.UpdatePlayerRequest = {
			token: updatePlayerState.player.token,
			first_name: updatePlayerState.player.first_name!,
			last_name: updatePlayerState.player.last_name!,
		};
		const updatePlayerResponse: GameCalls.UpdatePlayerResponse = yield call(GameCalls.updatePlayer, updatePlayerRequest);
		if (updatePlayerResponse.status && updatePlayerResponse.result) {
			yield put(updatePlayerSuccess());
		} else {
			yield put(updatePlayerFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(updatePlayerFail());
	}
}

function* watchUpdatePlayer() {
	const {updatePlayer} = GameSlice.gameAction;
	yield takeLeading(updatePlayer, handleUpdatePlayer);
}

/**
 ***********************************************************
 *********************** Get stats *************************
 ***********************************************************
 **/
function* handleGetStats() {
	const {getStatsSuccess, getStatsFail} = GameSlice.gameAction;
	try {
		const getStatsState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const getStatsRequest: GameCalls.GetStatsRequest = {
			gameId: 1,
		};
		const getStatsResponse: GameCalls.GetStatsResponse = yield call(GameCalls.getStats, getStatsRequest);
		if (getStatsResponse.status && getStatsResponse.stats) {
			yield put(getStatsSuccess(getStatsResponse.stats));
		} else {
			yield put(getStatsFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(getStatsFail());
	}
}

function* watchGetStats() {
	const {getStats} = GameSlice.gameAction;
	yield takeLeading(getStats, handleGetStats);
}

/**
 ***********************************************************
 ******************* Get basic secrets *********************
 ***********************************************************
 **/
function* hangleGetBasicSecrets() {
	const {getBasicSecretsSuccess, getBasicSecretsFail} = GameSlice.gameAction;
	try {
		const getBasicSecretsState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const getBasicSecretsRequest: GameCalls.GetBasicSecretsRequest = {};
		const getBasicSecretsResponse: GameCalls.GetBasicSecretsResponse = yield call(GameCalls.getBasicSecrets, getBasicSecretsRequest);
		if (getBasicSecretsResponse.status && getBasicSecretsResponse.basicSecrets) {
			yield put(getBasicSecretsSuccess(getBasicSecretsResponse.basicSecrets));
		} else {
			yield put(getBasicSecretsFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(getBasicSecretsFail());
	}
}

function* watchGetBasicSecrets() {
	const {getBasicSecrets} = GameSlice.gameAction;
	yield takeLeading(getBasicSecrets, hangleGetBasicSecrets);
}

/**
 ***********************************************************
 ********************** Get secret *************************
 ***********************************************************
 **/
function* handleGetSecret() {
	const {getSecretSuccess, getSecretFail} = GameSlice.gameAction;
	try {
		const getSecretState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const getSecretRequest: GameCalls.GetSecretRequest = {
			code: getSecretState.secret.code,
		};
		const getSecretResponse: GameCalls.GetSecretResponse = yield call(GameCalls.getSecret, getSecretRequest);
		if (getSecretResponse.status && getSecretResponse.secret && getSecretResponse.secret.value) {
			yield put(getSecretSuccess(getSecretResponse.secret));
		} else {
			yield put(getSecretFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(getSecretFail());
	}
}

function* watchGetSecret() {
	const {getSecret} = GameSlice.gameAction;
	yield takeLeading(getSecret, handleGetSecret);
}

/**
 ***********************************************************
 ********************* Add action **************************
 ***********************************************************
 **/
function* handleAddAction() {
	const {addActionSuccess, addActionFail} = GameSlice.gameAction;
	try {
		const addActionState: GameSlice.GameState = yield select(GameSlice.gameSelector.all);
		const addActionRequest: GameCalls.AddActionRequest = {
			playerToken: addActionState.player.token,
			secretCode: addActionState.secret.code,
		};
		const addActionResponse: GameCalls.AddActionResponse = yield call(GameCalls.addAction, addActionRequest);
		if (addActionResponse.status && addActionResponse.counter && addActionResponse.counter > 0) {
			yield put(addActionSuccess());
		} else {
			yield put(addActionFail());
		}
	} catch (err: any) {
		log.console.error('Saga error occured: ', err);
		history.push('/error', {status: 500});
		yield put(addActionFail());
	}
}

function* watchAddAction() {
	const {addAction} = GameSlice.gameAction;
	yield takeLeading(addAction, handleAddAction);
}

export {
	watchGetPlayer,
	watchGetPlayerSecrets,
	watchAddPlayer,
	watchUpdatePlayer,
	watchGetStats,
	watchGetBasicSecrets,
	watchGetSecret,
	watchAddAction,
};
