import {useState, useEffect} from 'react';

export const getLocalStorageValue = (key: string, defaultValue: string) => {
	const saved = localStorage.getItem(key);
	const initial = JSON.parse(saved!);
	return initial || defaultValue;
};

export const setLocalStorageValue = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorageValue = (key: string) => {
	localStorage.removeItem(key);
};

export const clearAllLocalStorage = () => {
	localStorage.clear();
};

export const useLocalStorage = (key: string, defaultValue: string) => {
	const [value, setValue] = useState(() => {
		return getLocalStorageValue(key, defaultValue);
	});

	useEffect(() => {
		setLocalStorageValue(key, value);
	}, [key, value]);

	return [value, setValue];
};
