import React from 'react';
import {Home, Game, Error} from 'pages';
import {Routes, Route, Navigate} from 'react-router-dom';

interface NavigationProps {}

const Navigation = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/game/:code" element={<Game />} />
			<Route path="/error" element={<Error />} />
			<Route path="*" element={<Navigate replace state={{status: 404}} to="/error" />} />
		</Routes>
	);
};

export default Navigation;
