export const info = (message: string, data?: any) => {
	if (process.env.REACT_APP_LOGS === 'off') return;
	if (!data) {
		console.log(`***Log - Info - %c${message}***`, 'color:blue; font-style:italic;');
	} else {
		console.log(`***Log - Info - %c${message} - %cstart***`, 'color:blue; font-style:italic;', 'color:default');
		console.log(data);
		console.log(`***Log - Info - %c${message} - %cend*****`, 'color:blue; font-style:italic;', 'color:default');
	}
};

export const success = (message: string, data?: any) => {
	if (process.env.REACT_APP_LOGS === 'off') return;
	if (!data) {
		console.log(`***Log - Success - %c${message}***`, 'color:green; font-style:italic;');
	} else {
		console.log(`***Log - Success - %c${message} - %cstart***`, 'color:green; font-style:italic;', 'color:default');
		console.log(data);
		console.log(`***Log - Success - %c${message} - %cend*****`, 'color:green; font-style:italic;', 'color:default');
	}
};

export const error = (message: string, data?: any) => {
	if (process.env.REACT_APP_LOGS === 'off') return;
	if (!data) {
		console.log(`***Log - Error - %c${message}***`, 'color:red; font-style:italic;');
	} else {
		console.log(`***Log - Error - %c${message} - %cstart***`, 'color:red; font-style:italic;', 'color:default');
		console.log(data);
		console.log(`***Log - Error - %c${message} - %cend*****`, 'color:red; font-style:italic;', 'color:default');
	}
};

export const debug = (message: string, data?: any) => {
	if (process.env.REACT_APP_LOGS === 'off') return;
	if (!data) {
		console.log(`***Log - Debug - %c${message}***`, 'color:orange; font-style:italic;');
	} else {
		console.log(`***Log - Debug - %c${message} - %cstart***`, 'color:orange; font-style:italic;', 'color:default');
		console.log(data);
		console.log(`***Log - Debug - %c${message} - %cend*****`, 'color:orange; font-style:italic;', 'color:default');
	}
};
