import React, {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {gameAction, gameSelector} from 'redux/features/game/slice';
import {Loader} from 'components';
import {dimensions, storage} from 'helpers';

function Home() {
	const {width} = dimensions.useWindowDimensions();
	const [counter, setCounter] = useState('');
	const {isLoading, player, stats, basicSecrets, playerSecrets} = useAppSelector(gameSelector.all);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const countDownDate = new Date('Mar 19, 2023 12:00:00').getTime();
		const x = setInterval(function () {
			const now = new Date().getTime();
			const distance = countDownDate - now;

			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			const result = days + 'g ' + hours + 's ' + minutes + 'd ' + seconds + 's ';
			setCounter(result);

			if (distance < 0) {
				clearInterval(x);
				setCounter('Oyun bitdi, hər birinizə təşəkkür edirik! 🥰');
			}
		}, 1000);
	}, []);

	useEffect(() => {
		dispatch(gameAction.getBasicSecrets());
		if (player && player.token) dispatch(gameAction.getPlayerSecrets({token: player.token}));
	}, [player, player.token]);

	const renderInfo = (emoji: string, key: string, value?: string | number) => {
		return width! > 640 ? (
			<div className="flex flex-col items-center justify-start flex-1">
				<div className="flex flex-col items-center justify-center">
					<span className="text-2xl xs:text-3xl sm:text-4xl lg:text-5xl">{emoji}</span>
					<span className="mt-2 text-base xs:text-lg sm:text-2xl lg:text-3xl font-bold text-center">{key}</span>
				</div>
				{value && (
					<div className="mt-2">
						<span className="font-semibold text-base sm:text-xl">{value}</span>
					</div>
				)}
			</div>
		) : (
			<div className="mb-4 flex items-center justify-start flex-1">
				<div className="flex items-center justify-center">
					<span className="text-xl mr-2 xs:text-3xl sm:text-4xl lg:text-5xl">{emoji}</span>
					<span className="font-semibold text-base xs:text-lg sm:text-2xl lg:text-3xl">
						{key}:{value && <span className="break-all font-bold">&nbsp;{value}</span>}
					</span>
				</div>
			</div>
		);
	};

	return (
		<main>
			<div className="flex flex-col pb-12">
				<div className="py-3 bg-black text-white h-[8%] w-full">
					<div className="px-8 xs:px-12 sm:px-16 lg:px-28 h-full w-full flex flex-col not-mobile:flex-row items-center">
						<div className="mb-2 not-mobile:mb-0 h-10 w-10">
							<img className="h-full w-full object-cover" src={require('assets/logo.jpg')} />
						</div>
						<div className="flex flex-col sm:flex-row items-center">
							<span className="ml-4 font-bold text-xl sm:text-2xl lg:text-3xl">Zein Games</span>
							<span className="mt-2 ml-4 sm:pl-4 text-xs xs:text-base sm:text-lg sm:border-l-4 border-l-white">
								Şəhərinizi kəşf edin və qazanın! 🔎 💰
							</span>
						</div>
					</div>
				</div>

				<div className="h-40 sm:h-64 w-full">
					<img className="h-full w-full object-cover" src={require('assets/qrworld.jfif')} />
				</div>

				<div className="mt-6">
					<div className="flex flex-row items-center justify-center">
						<div className="items-center justify-center flex flex-col">
							<span className="mb-2 text-[#F86180] font-bold text-lg xs:text-xl sm:text-2xl text-center">
								{player.first_name ?? ''} {player.last_name ?? ''}, xoş gəldiniz!
							</span>
							{/* <span className="font-bold text-lg xs:text-xl sm:text-2xl">Hazırsınız?</span> */}
							<span className="ml-2 mt-2 text-xs xs:text-base sm:text-lg">{counter}</span>
						</div>
					</div>

					<div className="mt-8 px-8 xs:px-12 sm:px-16 lg:px-28 flex flex-col sm:flex-row sm:justify-around">
						{renderInfo('✔️', 'Aktiv kod sayı', stats.active)}
						{renderInfo('❌', 'İstifadə edilmiş kod sayı', stats.inactive)}
					</div>

					<div className="sm:mt-12 px-8 xs:px-12 sm:px-16 lg:px-28 flex flex-col sm:flex-row sm:justify-around">
						{renderInfo('🤫', 'Şifrəniz', player.token)}
						{renderInfo('💰', 'Qazancınız', `${player.current_balance} AZN`)}
					</div>
				</div>

				<div className="px-8 xs:px-12 sm:px-16 lg:px-28">
					{renderInfo('🔑', 'Bütün kodlar')}
					<div className="mt-8 flex flex-row justify-around flex-wrap gap-4">
						{basicSecrets.map((basicSecret, index) => {
							return (
								<div key={index} className="min-w-[100px]">
									<span
										className={`font-semibold text-sm xs:text-base sm:text-lg lg:text-xl ${
											basicSecret.is_active ? 'text-green-500' : 'text-red-500'
										}`}>
										Kod - {index + 1}
									</span>
								</div>
							);
						})}
					</div>
				</div>

				<div className="mt-8 px-8 xs:px-12 sm:px-16 lg:px-28">
					{renderInfo('🔑', 'Tapdığınız kodlar')}
					<div className="mt-8 flex flex-row justify-around flex-wrap gap-4">
						{playerSecrets.map((playerSecret, index) => {
							return (
								<div key={index} className="min-w-[100px]">
									<span className={`font-semibold text-sm xs:text-base sm:text-lg lg:text-xl text-blue-500`}>{playerSecret.value}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{isLoading && <Loader />}
		</main>
	);
}

export default Home;
