import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {gameAction, gameSelector} from 'redux/features/game/slice';
import {Loader} from 'components';

function Game() {
	const {isLoading, player, secret} = useAppSelector(gameSelector.all);
	const {code} = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (code) dispatch(gameAction.getSecret({code}));
	}, []);

	useEffect(() => {
		if (player.token && secret.code && secret.value) dispatch(gameAction.addAction());
	}, [secret.value]);

	return (
		<main>
			<div className="flex flex-col h-screen">
				<div className="h-[25%] sm:h-[35%] w-full">
					<img className="h-full w-full object-cover" src={require('assets/qrworld.jfif')} />
				</div>
				<div className="px-8 xs:px-12 sm:px-16 lg:px-28">
					<div
						onClick={() => navigate('/')}
						className="
						cursor-pointer h-14 w-14 xs:h-20 xs:w-20 sm:h-24 sm:w-24 lg:h-32 lg:w-32
						-translate-y-6 xs:-translate-y-8 sm:-translate-y-12 lg:-translate-y-16">
						<img className="h-full w-full object-cover" src={require('assets/logo.jpg')} />
					</div>
					<div className="mt-8 xs:self-center items-center flex flex-col text-center">
						{!secret.is_active && secret.value === 'Expired' ? (
							<span className="font-bold text-lg xs:text-xl sm:text-2xl">Bu xəzinə artıq öz sahibini tapıb...</span>
						) : (
							<span className="font-bold text-lg xs:text-xl sm:text-2xl">
								{secret.value.length > 0 ? secret.value : 'Axtarmağa davam edin...'}
							</span>
						)}
						{secret.type === 1 && secret.is_active && secret.value.length > 0 && (
							<span className="mt-4 text-red-600 font-bold text-sm xs:text-base sm:text-lg">
								Təbrik edirik, siz qazandınız! Bu şifrəni qeyd edin, səhifədən çıxdıqdan sonra şifrə silinəcək!
							</span>
						)}
						<div className="mt-12 flex self-center items-center">
							<span onClick={() => navigate('/')} className="text-[#1036A2] cursor-pointer font-bold text-lg xs:text-xl sm:text-2xl">
								Ana səhifəyə qayıt ⬅
							</span>
						</div>
					</div>
				</div>
			</div>
			{isLoading && <Loader />}
		</main>
	);
}

export default Game;
