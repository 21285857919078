import axios, {AxiosResponse, AxiosError} from 'axios';
import {AxiosPayload, APIResponse} from './helper';
import {log} from 'helpers';
const API_URL = 'https://zeinapp.herokuapp.com/zein-games';
const axiosInstance = axios.create({
	timeout: Number(60000),
	baseURL: API_URL,
	responseType: 'json',
	headers: {
		'X-correlation-id': `webpage-request-${Math.floor(Math.random() * 100000)}`,
		'Content-Type': 'application/json',
	},
});

async function get<ReturnType>(payload: AxiosPayload): Promise<AxiosResponse<APIResponse<ReturnType>>> {
	return axiosInstance
		.get(payload.path, {})
		.then((res: AxiosResponse<APIResponse<ReturnType>>): Promise<AxiosResponse<APIResponse<ReturnType>>> => {
			if (res && res.data.status === 'success') return Promise.resolve(res);
			if (res && res.data.status === 'fail') {
				const errorCode = 'Server error occured...';
				log.console.error(errorCode);
				return Promise.resolve(res);
			} else throw new Error('Response error');
		})
		.catch((error: AxiosError | any): any => {
			log.console.error('Server get error', error?.message);
			log.console.error(error);
			if (isCleanAxiosError(error)) return Promise.resolve(error.response);
			else return Promise.reject(error);
		});
}

async function post<ReturnType>(payload: AxiosPayload): Promise<AxiosResponse<APIResponse<ReturnType>>> {
	return axiosInstance
		.post(payload.path, payload.data, {
			params: payload.params,
		})
		.then((res: AxiosResponse<APIResponse<ReturnType>>): Promise<AxiosResponse<APIResponse<ReturnType>>> => {
			if (res && res.data.status === 'success') return Promise.resolve(res);
			if (res && res.data.status === 'fail') {
				const errorCode = 'Server error occured...';
				log.console.error(errorCode);
				return Promise.resolve(res);
			} else throw new Error('Response error');
		})
		.catch((error: AxiosError | any): any => {
			log.console.error('Server post error', error?.message);
			log.console.error(error);
			if (isCleanAxiosError(error)) return Promise.resolve(error.response);
			return Promise.reject(error);
		});
}

async function put<ReturnType>(payload: AxiosPayload): Promise<AxiosResponse<APIResponse<ReturnType>>> {
	return axiosInstance
		.put(payload.path, payload.data, {})
		.then((res: AxiosResponse<APIResponse<ReturnType>>): Promise<AxiosResponse<APIResponse<ReturnType>>> => {
			if (res && res.data.status === 'success') return Promise.resolve(res);
			if (res && res.data.status === 'fail') {
				const errorCode = 'Server error occured...';
				log.console.error(errorCode);
				return Promise.resolve(res);
			} else throw new Error('Response error');
		})
		.catch((error: AxiosError | any): any => {
			log.console.error('Server put error', error?.message);
			log.console.error(error);
			if (isCleanAxiosError(error)) return Promise.resolve(error.response);
			return Promise.reject(error);
		});
}

async function patch<ReturnType>(payload: AxiosPayload): Promise<AxiosResponse<APIResponse<ReturnType>>> {
	return axiosInstance
		.patch(payload.path, payload.data, {
			params: payload.params,
		})
		.then((res: AxiosResponse<APIResponse<ReturnType>>): Promise<AxiosResponse<APIResponse<ReturnType>>> => {
			if (res && res.data.status === 'success') return Promise.resolve(res);
			if (res && res.data.status === 'fail') {
				const errorCode = 'Server error occured...';
				log.console.error(errorCode);
				return Promise.resolve(res);
			} else throw new Error('Response error');
		})
		.catch((error: AxiosError | any): any => {
			log.console.error('Server patch error', error?.message);
			log.console.error(error);
			if (isCleanAxiosError(error)) return Promise.resolve(error.response);
			return Promise.reject(error);
		});
}

async function del<ReturnType>(payload: AxiosPayload): Promise<AxiosResponse<APIResponse<ReturnType>>> {
	return axiosInstance
		.delete(payload.path, {})
		.then((res: AxiosResponse<APIResponse<ReturnType>>): Promise<AxiosResponse<APIResponse<ReturnType>>> => {
			if (res && res.data.status === 'success') return Promise.resolve(res);
			if (res && res.data.status === 'fail') {
				const errorCode = 'Server error occured...';
				log.console.error(errorCode);
				return Promise.resolve(res);
			} else throw new Error('Response error');
		})
		.catch((error: AxiosError | any): any => {
			log.console.error('Server delete error', error?.message);
			log.console.error(error);
			if (isCleanAxiosError(error)) return Promise.resolve(error.response);
			return Promise.reject(error);
		});
}

const isCleanAxiosError = (error: AxiosError | any): boolean =>
	axios.isAxiosError(error) && error.message != 'Network Error' && !error.message.includes('timeout');

export {get, post, put, patch, del};
