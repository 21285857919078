import {
	deviceType,
	mobileVendor,
	mobileModel,
	osName,
	osVersion,
	browserName,
	fullBrowserVersion,
	engineName,
	engineVersion,
	getUA,
} from 'react-device-detect';

export const getDeviceInfo = () => {
	return {
		device_type: deviceType,
		mobile_vendor: mobileVendor,
		mobile_model: mobileModel,
		os_name: osName,
		os_version: osVersion,
		browser_name: browserName,
		browser_version: fullBrowserVersion,
		engine_name: engineName,
		engine_version: engineVersion,
		user_agent: getUA,
	};
};
