import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {gameAction, gameSelector} from 'redux/features/game/slice';

const Modal = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const {player} = useAppSelector(gameSelector.all);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!player.first_name || !player.last_name) setIsOpen(true);
		else setIsOpen(false);
	}, [player.first_name, player.last_name]);

	const onSubmit = () => {
		if (firstName.length > 3 && lastName.length > 3) dispatch(gameAction.updatePlayer({firstName, lastName}));
	};

	return isOpen ? (
		<div>
			<div className="z-10 opacity-80 fixed inset-0 bg-black"></div>
			<div className={`fixed z-20 inset-0 overflow-y-auto`}>
				<div className="flex items-center justify-center min-h-screen px-3">
					<div className="bg-white rounded-lg shadow-lg px-3 py-6 xs:p-8 w-full max-w-md">
						<div>
							<div className="mb-2 xs:text-lg font-semibold">Zəhmət olmasa oyuna qoşulmaq üçün adınızı və soyadınızı daxil edin. 👻</div>
							<div className="mb-2 xs:mb-4">
								<label className="block text-gray-700 font-bold mb-2" htmlFor="firstName">
									Ad
								</label>
								<input
									className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									id="firstName"
									type="text"
									placeholder="Adınızı qeyd edin"
									value={firstName}
									onChange={(event) => setFirstName(event.target.value)}
								/>
							</div>
							<div className="mb-4">
								<label className="block text-gray-700 font-bold mb-2" htmlFor="lastName">
									Soyad
								</label>
								<input
									className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									id="lastName"
									type="text"
									placeholder="Soyadınızı qeyd edin"
									value={lastName}
									onChange={(event) => setLastName(event.target.value)}
								/>
							</div>
							<div className="flex justify-end">
								<button
									className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
									onClick={() => onSubmit()}>
									Təsdiq
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default Modal;
