import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {rootReducer, rootSaga} from 'redux/features';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const createStore = () => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				thunk: false,
			}).concat(middlewares),
	});
	sagaMiddleware.run(rootSaga);
	return {store};
};

export const reduxRoot = createStore();
export type RootState = ReturnType<typeof reduxRoot.store.getState>;
export type AppDispatch = typeof reduxRoot.store.dispatch;
