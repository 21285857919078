import React from 'react';
import {MainRouter, Navigation, history} from 'helpers/routing';
import {log} from 'helpers';
import {InfoModal} from 'modals';

const loading = (
	<div>
		<h1>Loading...</h1>
	</div>
);

function App() {
	log.console.info('Zein Games App rerendered');
	log.console.info('ENV', process.env);

	return (
		<MainRouter history={history}>
			<React.Suspense fallback={loading}>
				<Navigation />
				<InfoModal />
			</React.Suspense>
		</MainRouter>
	);
}

export default App;
