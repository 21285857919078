import React from 'react';
import {BrowserHistory} from 'history';
import {Router} from 'react-router-dom';

interface MainRouterProps {
	history: BrowserHistory;
	children?: React.ReactNode;
	basename?: string;
}

const MainRouter = ({basename, children, history}: MainRouterProps): React.ReactElement | null => {
	const [state, setState] = React.useState({
		action: history.action,
		location: history.location,
	});

	React.useLayoutEffect(() => history.listen(setState), [history]);

	return (
		<Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
			{children}
		</Router>
	);
};

export default MainRouter;
