import {useState, useEffect} from 'react';

export function useWindowDimensions() {
	const hasWindow = typeof window !== 'undefined';

	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	function getWindowDimensions() {
		const width = hasWindow ? window.innerWidth : null;
		const height = hasWindow ? window.innerHeight : null;
		return {
			width,
			height,
		};
	}

	function handleResize() {
		setWindowDimensions(getWindowDimensions());
	}

	useEffect(() => {
		if (hasWindow) {
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [hasWindow]);

	return windowDimensions;
}
