import React from 'react';
import {createRoot} from 'react-dom/client';
import App from 'App';
import {Provider} from 'react-redux';
import {reduxRoot} from 'redux/store';
import {gameAction} from 'redux/features/game/slice';
import {log, hash, storage} from 'helpers';
import 'index.css';
log.console.info('Zein Games App initialized');

let token = storage.local.getLocalStorageValue('token', '');
if (!token) {
	token = hash.generateId(16);
	storage.local.setLocalStorageValue('token', token);
}

reduxRoot.store.dispatch(gameAction.getStats());
reduxRoot.store.dispatch(gameAction.getPlayer({token}));
/**
 ***********************************************************
 ******************* ReactDOM render ***********************
 ***********************************************************
 **/

const ZeinGames = () => {
	return (
		<React.StrictMode>
			<Provider store={reduxRoot.store}>
				<App />
			</Provider>
		</React.StrictMode>
	);
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<ZeinGames />);
